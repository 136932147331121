<template>
  <v-container>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">{{
          $t('dialogMessages.itemDeleteConfirmation')
        }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">{{
            $t('buttons.cancel')
          }}</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm">{{
            $t('buttons.ok')
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogModifyStage" max-width="700px">
      <v-card>
        <v-card-title class="text-h6 justify-center">{{
          `${$t('dialogMessages.stageChangeDialog')} ${claimNumberToModify}`
        }}</v-card-title>
        <v-card-text>
          <v-autocomplete
            dense
            :items="closedStagesItems"
            :label="$t('wizard.stage')"
            v-model="stageIdToModify"
            clearable
          ></v-autocomplete>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDialogModifyStage">{{
            $t('buttons.cancel')
          }}</v-btn>
          <v-btn
            v-if="claimIdToModify && stageIdToModify"
            color="warning darken-1"
            text
            @click="modifyStageConfirm"
            >{{ $t('buttons.ok') }}</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-btn v-if="!isClient" class="success" @click="downloadCsv">
      {{ $t('buttons.exportClaims') }}
    </v-btn>
    <v-btn class="primary" @click="downloadClientCsv">
      {{ $t('buttons.exportClientClaims') }}
    </v-btn>
    <v-row no-gutters>
      <v-col cols="3">
        <v-text-field
          prepend-inner-icon="mdi-magnify"
          v-model="searchString"
          :placeholder="$t('placeholders.search')"
          clearable
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" sm="10" md="8">
        <v-chip-group
          v-model="filters.selection"
          multiple
          active-class="primary--text"
        >
          <v-chip v-if="!isClient" value="Followed">
            {{ $t('filters.followed') }}
          </v-chip>
          <v-chip value="Open"> {{ $t('filters.open') }} </v-chip>
          <v-chip value="Closed"> {{ $t('filters.closed') }} </v-chip>
          <v-chip value="Standard Cargo">
            {{ $t('filters.standardCargo') }}
          </v-chip>
          <v-chip value="Vehicle"> {{ $t('filters.vehicle') }} </v-chip>
          <v-chip v-if="!isClient" value="New">
            {{ $t('filters.new') }}
          </v-chip>
          <v-chip value="2023"> 2023 </v-chip>
          <v-chip value="Claim To Third Party">{{
            $t('filters.claimToThirdParty')
          }}</v-chip>
          <v-chip value="Claim To Entrusted Property">{{
            $t('filters.claimToEntrustedProperty')
          }}</v-chip>
          <v-chip value="Freight Forwarding Claim">{{
            $t('filters.freightForwardingClaim')
          }}</v-chip>
          <v-chip value="Marked">{{ $t('filters.marked') }}</v-chip>
        </v-chip-group>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="3">
        <v-autocomplete
          dense
          v-if="clients"
          :items="clientSelectItems"
          v-model="filters.client"
          :label="$t('filters.client')"
          clearable
        ></v-autocomplete>
      </v-col>
      <v-col cols="3">
        <v-menu
          v-model="fromDatepicker_show"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              dense
              v-model="filters.fromDate"
              :label="$t('filters.from')"
              v-on="on"
              clearable
            >
            </v-text-field>
          </template>
          <v-date-picker
            v-model="filters.fromDate"
            @input="fromDatepicker_show = false"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="3">
        <v-menu
          v-model="toDatepicker_show"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              dense
              v-model="filters.toDate"
              :label="$t('filters.to')"
              v-on="on"
              clearable
            >
            </v-text-field>
          </template>
          <v-date-picker
            v-model="filters.toDate"
            @input="toDatepicker_show = false"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>

    <v-data-table
      :headers="_headersWithActions"
      :items="sortedClaimsWithDuration"
      :item-class="classChecker"
      :loading="isLoading"
      :header-props="headerProps"
      :items-per-page="-1"
      class="elevation-1"
    >
      <template v-slot:item.id="{ item }">
        <router-link :to="`/cmr/${item.id}/`">
          <span>{{
            item.claimNumber ? item.claimNumber : item.id
          }}</span></router-link
        >
      </template>
      <template v-slot:item.amountReserve="{ item }">
        <span>
          {{ formatAmount(item.amountReserve) }}
        </span>
      </template>
      <template v-slot:item.unloadingDate="{ item }">
        <span>
          {{
            item.unloadingDate == '0001-01-01T00:00:00Z'
              ? ''
              : item.unloadingDate
              ? formatDate(item.unloadingDate)
              : ''
          }}
        </span>
      </template>
      <template v-slot:item.unloadingDate="{ item }">
        <span>
          {{
            item.unloadingDate == '0001-01-01T00:00:00Z'
              ? ''
              : item.unloadingDate
              ? formatDate(item.unloadingDate)
              : ''
          }}
        </span>
      </template>
      <template v-slot:item.circumstance.name="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              {{ circumstanceName(item) }}
            </span>
          </template>
          <span>
            {{ circumstanceTooltip(item) }}
          </span>
        </v-tooltip>
      </template>
      <template v-slot:item.stage.displayName="{ item }">
        <span :class="stageColor(item.stage.displayName)">
          {{ $t(`stages["${item.stage.displayName}"]`) }}
        </span>
      </template>
      <template v-slot:item.stageChangeDate="{ item }">
        <span>
          {{ item.stageChangeDate ? formatDate(item.stageChangeDate) : '' }}
        </span>
      </template>
      <template v-slot:item.outcome="{ item }">
        <span>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                {{ truncate(outcomeFormatter(item.outcome), 8) }}
              </span>
            </template>
            <span>
              {{ outcomeFormatter(item.outcome) }}
            </span>
          </v-tooltip>
        </span>
      </template>
      <template v-slot:item.colour="{ item }">
        <BaseCheckbox
          dense
          v-model="item.colour"
          :readonly="true"
          :disabled="true"
        ></BaseCheckbox>
      </template>
      <template v-slot:item.description="{ item }">
        <span>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                {{ truncate(item.description, 8) }}
              </span>
            </template>
            <span>
              {{ item.description }}
            </span>
          </v-tooltip>
        </span>
      </template>
      <template v-slot:item.vehicleVINs="{ item }">
        <span>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                {{
                  item.cargoType == 'vehicle'
                    ? truncate(item.vehicleVINs, 17)
                    : truncate(formatReportedDamage(item.reportedDamage), 9)
                }}
              </span>
            </template>
            <span>
              {{
                item.cargoType == 'vehicle'
                  ? item.vehicleVINs
                  : formatReportedDamage(item.reportedDamage)
              }}
            </span>
          </v-tooltip>
        </span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon v-if="isAdmin" small @click="deleteItem(item)">
          mdi-delete
        </v-icon>
        <v-icon v-if="!isClient" small @click="modifyStage(item)">
          mdi-swap-vertical
        </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import dayjs from 'dayjs'
import { exportCsv } from './../utils/CMRExportCSV'
import { exportClientCsv } from './../utils/CMRClientExportCSV'
import { filterOutFollowers } from './../utils/exportUtils'
import {
  getTimeDifferenceInDays,
  getTimeDifferenceInDaysBetweenTwoDates,
  removeUnwantedSeparators,
  getTimeDifferenceInDaysUntilNow,
} from './../utils/formattingHelpers'
import { mapState } from 'vuex'
import { translateArray } from '../store/helpers'
export default {
  data() {
    return {
      isLoading: true,
      hideHeadersFromClients: ['colour', 'duration', 'FIRDuration'],
      hideHeadersFromNonClients: ['driver.name'],
      fromDatepicker_show: false,
      toDatepicker_show: false,
      dialogDelete: false,
      dialogModifyStage: false,
      dialog: false,
      editedIndex: -1,
      editedItem: {},
      filters: {
        selection: [],
        client: '',
        fromDate: '',
        toDate: '',
      },
      searchString: '',
      //Variables for stageChange
      stageIdToModify: null,
      claimIdToModify: null,
      claimNumberToModify: null,
    }
  },
  created() {
    this.$store.dispatch('client/getClients')
    this.$store.dispatch('circumstance/getCircumstances', 2)
    this.$store
      .dispatch('mtpl/getClaims', { pipelineId: 2, orderBy: 'reportedDate' })
      .then(() => {
        this.isLoading = false
      })
    // if (sessionStorage.getItem('MTPLFilters')) {
    //   let JSONFilters = sessionStorage.getItem('MTPLFilters')
    //   let filters = JSON.parse(JSONFilters)
    //   // TODO: Implement chip active change.
    //   this.$nextTick(() => {
    //     this.filters = Object.assign({}, this.filters, filters)
    //   })
    // }
  },
  mounted() {
    if (this.isClient) {
      this.filters.selection = ['Open']
    } else {
      this.filters.selection = ['Followed']
    }
    if (!this.isClient) {
      this.$store.dispatch('stage/getStages', 2)
    }
    if (!this.isClient && localStorage.getItem('CMRFilters')) {
      let JSONFilters = localStorage.getItem('CMRFilters')
      let filters = JSON.parse(JSONFilters)
      this.$nextTick(() => {
        this.filters = Object.assign({}, this.filters, filters)
      })
    }
  },
  beforeDestroy() {
    this.$store.dispatch('mtpl/clearClaims')
    localStorage.setItem('CMRFilters', JSON.stringify(this.filters))
  },
  beforeUnmount() {},
  computed: {
    ...mapState({
      mtpls: (state) => state.mtpl.mtpls,
      user: (state) => state.user.user,
      stage: (state) => state.stage,
      clients: (state) => state.client.clients,
      circumstances: (state) => state.circumstance.circumstances,
    }),
    headers() {
      return [
        {
          text: this.$i18n.t('headers.mark'),
          value: 'colour',
        },
        {
          text: this.$i18n.t('headers.stage'),
          value: 'stage.displayName',
        },
        {
          text: this.$i18n.t('headers.stageChangeDate'),
          value: 'stageChangeDate',
        },
        {
          text: this.$i18n.t('headers.client'),
          value: 'client.name',
        },
        {
          text: this.$i18n.t('headers.claim'),
          value: 'id',
        },
        {
          text: this.$i18n.t('headers.VIN/CT'),
          value: 'vehicleVINs',
        },
        {
          text: this.$i18n.t('headers.icn'),
          value: 'insuranceClaimNumber',
        },
        {
          text: this.$i18n.t('headers.unloadingDate'),
          value: 'unloadingDate',
        },
        {
          text: this.$i18n.t('headers.reserve'),
          value: 'amountReserve',
        },
        {
          text: this.$i18n.t('headers.driver'),
          value: 'driver.name',
        },
        {
          text: this.$i18n.t('headers.circumstance'),
          value: 'circumstance.name',
        },
        {
          text: this.$i18n.t('headers.outcome'),
          value: 'outcome',
        },
        {
          text: this.$i18n.t('headers.duration'),
          value: 'duration',
        },
        {
          text: this.$i18n.t('headers.firDuration'),
          value: 'FIRDuration',
        },
      ]
    },

    headersWithActions() {
      return [
        ...this.headers,
        {
          text: this.$i18n.t('headers.actions'),
          value: 'actions',
          sortable: false,
        },
      ]
    },
    _headersWithActions() {
      return this.headersWithActions
        .filter(
          (h) =>
            !this.hideHeadersFromClients.includes(h.value) || !this.isClient
        )
        .filter(
          (h) =>
            !this.hideHeadersFromNonClients.includes(h.value) || this.isClient
        )
    },
    sortedClaims() {
      if (this.filteredClaims) {
        return this.filteredClaims.concat().sort(this.sortClaimsDefault)
      } else {
        return this.filteredClaims
      }
    },
    sortedClaimsWithDuration() {
      return this.sortedClaims.map((c) => {
        return {
          ...c,
          duration: c.processDuration
            ? getTimeDifferenceInDays(c.reportedDate, c.processDuration)
            : getTimeDifferenceInDaysUntilNow(c.reportedDate),
          FIRDuration:
            c.finalStageReachedDate && c.finalInvoiceReceivedDate
              ? getTimeDifferenceInDaysBetweenTwoDates(
                  c.finalInvoiceReceivedDate,
                  c.finalStageReachedDate
                )
              : '',
        }
      })
    },
    filteredClaims() {
      let conditions = []

      this.filters.selection.includes('Open') &&
        conditions.push(this.filterOpenClaims)
      this.filters.selection.includes('Closed') &&
        conditions.push(this.filterClosedClaims)
      this.filters.selection.includes('Followed') &&
        conditions.push(this.filterFollowedClaims)
      this.filters.selection.includes('2023') &&
        conditions.push(this.filterCurrentYearClaims)
      this.filters.selection.includes('New') &&
        conditions.push(this.filterNewClientClaims)
      this.filters.selection.includes('Standard Cargo') &&
        conditions.push(this.filterStandardCargoClaims)
      this.filters.selection.includes('Vehicle') &&
        conditions.push(this.filterVehicleClaims)
      this.filters.selection.includes('Claim To Third Party') &&
        conditions.push(this.filterIsClaimToThirdParty)
      this.filters.selection.includes('Claim To Entrusted Property') &&
        conditions.push(this.filterIsClaimToEntrustedProperty)
      this.filters.selection.includes('Freight Forwarding Claim') &&
        conditions.push(this.filterIsFreightForwardingClaim)
      this.filters.selection.includes('Marked') &&
        conditions.push(this.filterMarkedClaims)

      if (this.searchString !== '' && this.searchString != null) {
        conditions.push(this.filterSearch)
      }

      if (this.filters.client !== '' && this.filters.client != null) {
        conditions.push(this.filterClaimClient)
      }

      if (this.filters.fromDate !== '' && this.filters.fromDate != null) {
        conditions.push(this.filterClaimFromDate)
      }

      if (this.filters.toDate !== '' && this.filters.toDate != null) {
        conditions.push(this.filterClaimToDate)
      }

      if (conditions.length > 0 && this.mtpls.claims) {
        return this.mtpls.claims.filter((claim) => {
          return conditions.every((condition) => {
            return condition(claim)
          })
        })
      }

      return this.mtpls.claims
    },
    isClient() {
      return this.$store.getters['user/role'] == 'client'
    },
    isAdmin() {
      return this.$store.getters['user/role'] == 'admin'
    },
    headerProps() {
      return {
        sortByText: this.$i18n.t(`dataTable.sortBy`),
      }
    },
    clientSelectItems() {
      return this.clients.map((c) => ({
        text: c.name,
        value: c.id,
      }))
    },
    closedStagesItems() {
      return this.stage.stages
        .filter((s) => s.isFinal)
        .map((s) => ({
          text: s.displayName,
          value: s.id,
        }))
    },
  },
  methods: {
    formatReportedDamage(reportedDamage) {
      if (!Array.isArray(reportedDamage) || reportedDamage == '""') {
        return ''
      } else {
        let RDArray = JSON.parse(reportedDamage)
        RDArray = translateArray(RDArray, 'standardCargoItems')
        return RDArray.map((rd) => {
          return typeof rd == 'object' ? rd.text : rd
        }).join(',')
      }
    },
    outcomeFormatter(outcome) {
      if (!outcome) {
        return ''
      }
      if (outcome.endsWith('\n')) {
        outcome = outcome.slice(0, -1)
      }
      let outcomeParts = outcome.split('\n')
      return outcomeParts.length > 1
        ? outcomeParts[outcomeParts.length - 1]
        : outcomeParts[0]
    },
    classChecker(item) {
      if (item.createdByClient) {
        return 'custom-bg'
      } else {
        return ''
      }
    },
    capitalizeFirstLetter(string) {
      if (string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      }
      return ''
    },
    filterFollowedClaims(item) {
      return item.followers.map((f) => f.id).includes(this.user.id)
    },
    filterStandardCargoClaims(item) {
      return item.cargoType == 'standard cargo'
    },
    filterVehicleClaims(item) {
      return item.cargoType == 'vehicle'
    },
    filterClosedClaims(item) {
      return item.stage.isFinal
    },
    filterIsClaimToThirdParty(item) {
      return item.isClaimToThirdParty
    },
    filterIsClaimToEntrustedProperty(item) {
      return item.isClaimToEntrustedProperty
    },
    filterIsFreightForwardingClaim(item) {
      return item.isFreightForwardingClaim
    },
    filterOpenClaims(item) {
      return !item.stage.isFinal
    },
    filterCurrentYearClaims(item) {
      const yearToCheck = 2023
      return dayjs(item.reportedDate).isSame(`${yearToCheck}`, 'year')
    },
    filterNewClientClaims(item) {
      return (
        item.createdByClient &&
        (item.claimNumber == null || item.claimNumber == '')
      )
    },
    filterMarkedClaims(item) {
      return item.colour != null
    },
    sortClaimsDefault(a, b) {
      let aCondition = a.createdByClient && a.claimNumber === ''
      let bCondition = b.createdByClient && b.claimNumber === ''
      if (Number(aCondition) < Number(bCondition)) {
        return 1
      }
      if (Number(aCondition) > Number(bCondition)) {
        return -1
      }
      return 0
    },
    getCurrentTimeString() {
      let timestamp = new Date()
      return (
        '' +
        timestamp.getFullYear() +
        timestamp.getMonth() +
        timestamp.getDay() +
        timestamp.getHours() +
        timestamp.getMinutes() +
        timestamp.getSeconds()
      )
    },
    filterClaimClient(item) {
      return item.client.id == this.filters.client
    },
    filterSearch(item) {
      return (
        item.stage?.displayName
          ?.toLowerCase()
          .includes(this.searchString.toLowerCase()) ||
        item.client?.name
          .toLowerCase()
          .includes(this.searchString.toLowerCase()) ||
        item.claimNumber
          ?.toLowerCase()
          .includes(this.searchString.toLowerCase()) ||
        item.vin?.toLowerCase().includes(this.searchString.toLowerCase()) ||
        item.id.toString().includes(this.searchString) ||
        item.insuranceClaimNumber
          ?.toLowerCase()
          .includes(this.searchString.toLowerCase()) ||
        item.unloadingDate?.includes(this.searchString) ||
        this.formatAmount(item.amountReserve)
          ?.toString()
          .includes(this.searchString) ||
        item.claimingParty
          ?.toLowerCase()
          .includes(this.searchString.toLowerCase()) ||
        item.vehicleVINs?.toString().includes(this.searchString) ||
        this.formatReportedDamage(item.reportedDamage).includes(
          this.searchString
        ) ||
        item.circumstance?.name
          ?.toLowerCase()
          .includes(this.searchString.toLowerCase()) ||
        (item.circumstance?.name &&
          this.$i18n
            .t(`circumstances["${item.circumstance?.name}"]`)
            ?.toLowerCase()
            .includes(this.searchString.toLowerCase())) ||
        (item.stage?.displayName &&
          this.$i18n
            .t(`stages["${item.stage?.displayName}"]`)
            .toLowerCase()
            .includes(this.searchString.toLowerCase()))
      )
      /*
       */
    },
    filterClaimFromDate(item) {
      let fromDate = dayjs(this.filters.fromDate)
      let createdDate = dayjs(item.unloadingDate)
      return createdDate.diff(fromDate) >= 0
    },
    filterClaimToDate(item) {
      let toDate = dayjs(this.filters.toDate)
      let createdDate = dayjs(item.unloadingDate)
      return toDate.diff(createdDate) >= 0
    },
    downloadCsv() {
      if (this.isClient) {
        return
      }
      let exportArray = []

      // eslint-disable-next-line no-unused-vars
      const formatDamagedPropertyTypes = (property, string) => {
        if (property.includes('[') && property.includes(']')) {
          // let parsedArray = JSON.parse(property)
          let parsedArray
          try {
            parsedArray = JSON.parse(property)
          } catch (e) {
            console.log(string)
          }
          return parsedArray.map((pa) => {
            return typeof pa == 'object' ? pa.value : pa
          })
        } else {
          return ''
        }
      }
      this.sortedClaimsWithDuration.forEach((claim) => {
        if (claim.cargoType !== 'vehicle') {
          exportArray.push(claim)
        } else if (typeof claim.cargoVehicles == 'undefined') {
          exportArray.push(claim)
        } else {
          claim.cargoVehicles.forEach((cargoVehicle) => {
            let claimWithSingleCargoVehicle = { ...claim, cargoVehicle }
            exportArray.push(claimWithSingleCargoVehicle)
          })
        }
      })

      let dataToExport = exportArray.map((c) => ({
        mark: c.colour ? 'Y' : '',
        stage: this.nullCheck(c.stage.displayName),
        stageChangeDate: c.stageChangeDate
          ? this.formatDate(c.stageChangeDate)
          : '',
        client: this.nullCheck(c.client.name),
        isCargoClaim: c.isCargoClaim ? 'Cargo claim' : '',
        isClaimToThirdParty: c.isClaimToThirdParty
          ? 'Claim to third party'
          : '',
        isClaimToEntrustedProperty: c.isClaimToEntrustedProperty
          ? 'Claim to entrusted property'
          : '',
        isFreightForwardingClaim: c.isFreightForwardingClaim
          ? 'Freight forwarding claim'
          : '',
        isWarehouseKeepersLiability: c.isWarehouseKeepersLiability
          ? 'Warehouse Keepers Liability'
          : '',
        insuranceProvider: this.nullCheck(c.insuranceProvider),
        claimNumber: c.claimNumber ? c.claimNumber : c.id,
        insuranceClaimNumber: this.nullCheck(c.insuranceClaimNumber),
        reportedDate: this.formatDate(this.nullCheck(c.reportedDate)),
        unloadingDate: this.formatDate(this.nullCheck(c.unloadingDate)),
        incidentDate: this.formatDate(this.nullCheck(c.incidentDate)),
        dateDeclaredForInsurer: this.formatDate(
          this.nullCheck(c.dateDeclaredForInsurer)
        ),
        VehicleRegistration: c.vehicle?.registration
          ? c.vehicle.registration
          : '',
        driver: c.driver?.name ? c.driver.name : '',
        amountReserve: c.amountReserve
          ? this.formatAmount(c.amountReserve)
          : '',
        reserveReceived: c.reserveReceived ? 'Reserve received' : '',
        amountClaimed: c.amountClaimed
          ? this.formatAmount(c.amountClaimed)
          : '',
        cargoType: this.capitalizeFirstLetter(this.nullCheck(c.cargoType)),
        reportedDamage:
          c.reportedDamage && c.cargoType !== 'vehicle'
            ? formatDamagedPropertyTypes(c.reportedDamage, `claim: ${c.id}`)
            : '',
        cargoVehicleVIN: c.cargoVehicle ? c.cargoVehicle.vin : '',
        cargoVehicleDamagedParts:
          c.cargoVehicle && c.cargoVehicle.damagedParts
            ? formatDamagedPropertyTypes(
                c.cargoVehicle.damagedParts,
                `vehicle: ${c.cargoVehicle.id}`
              )
            : '',
        cargoVehicleMake: c.cargoVehicle ? c.cargoVehicle.make : '',
        cargoVehicleModel: c.cargoVehicle ? c.cargoVehicle.model : '',
        cargoVehicleCondition: c.cargoVehicle
          ? this.capitalizeFirstLetter(c.cargoVehicle.condition)
          : '',
        circumstance: c.circumstance ? c.circumstance.name : '',
        entrustedPropertyCircumstance: c.claimToEntrustedPropertyCircumstanceId
          ? this.circumstances.find(
              (circumstance) =>
                circumstance.id == c.claimToEntrustedPropertyCircumstanceId
            ).name
          : '',
        description: removeUnwantedSeparators(this.nullCheck(c.description)),
        cargoWeight: this.nullCheck(c.cargoWeight),
        finalInvoiceReceivedDate: this.formatDate(
          this.nullCheck(c.finalInvoiceReceivedDate)
        ),
        payoutDate: this.formatDate(this.nullCheck(c.payoutDate)),
        payoutReceived: c.payoutReceived
          ? this.formatAmount(c.payoutReceived)
          : '',
        amountRejected: c.amountRejected
          ? this.formatAmount(c.amountRejected)
          : '',
        amountReduced: c.amountReduced
          ? this.formatAmount(c.amountReduced)
          : '',
        outcome: removeUnwantedSeparators(this.nullCheck(c.outcome)),
        outcomeForEmployees: removeUnwantedSeparators(
          this.nullCheck(c.outcomeForEmployees)
        ),
        loadingLocation: removeUnwantedSeparators(
          this.nullCheck(c.loadingLocation)
        ),
        unloadLocation: removeUnwantedSeparators(
          this.nullCheck(c.unloadLocation)
        ),
        incidentLocation: removeUnwantedSeparators(
          this.nullCheck(c.incidentLocation)
        ),
        incidentLatitude: this.nullCheck(c.incidentLatitude),
        incidentLongitude: this.nullCheck(c.incidentLongitude),
        followers: filterOutFollowers(c.followers),
        duration: this.nullCheck(c.duration),
        FIRDuration:
          c.finalStageReachedDate && c.finalInvoiceReceivedDate
            ? getTimeDifferenceInDaysBetweenTwoDates(
                c.finalInvoiceReceivedDate,
                c.finalStageReachedDate
              )
            : '',
        factualCarrier: removeUnwantedSeparators(
          this.nullCheck(c.factualCarrier)
        ),
        isUrgent: c.isUrgent ? 'Answer for claimant within 24-72 hours' : '',
        allowDirectContact: c.allowDirectContact
          ? 'Allow direct contact with claimant'
          : '',
        claimingParty: this.nullCheck(c.claimingParty),
        cargoQuantity: this.nullCheck(c.cargoQuantity),
        requiredFileCategories: c.requiredFileCategories
          ? JSON.parse(c.requiredFileCategories).join(',')
          : '',

        // vin: c.vin ? c.vin : '',
        // reportedDate:
        //   c.reportedDate == '0001-01-01T00:00:00Z'
        //     ? ''
        //     : this.formatDate(this.nullCheck(c.reportedDate)),
        // description: removeUnwantedSeparators(this.nullCheck(c.description)),
        // location: removeUnwantedSeparators(this.nullCheck(c.incidentLocation)),
        // amountPaid: this.nullCheck(c.amountPaid),
      }))
      const link = document.createElement('a')
      link.setAttribute('href', exportCsv(dataToExport))
      link.setAttribute(
        'download',
        `claimExport ${this.getCurrentTimeString()}.csv`
      )
      link.click()
    },
    downloadClientCsv() {
      let exportArray = []

      // eslint-disable-next-line no-unused-vars
      const formatDamagedPropertyTypes = (property, string) => {
        if (property.includes('[') && property.includes(']')) {
          // let parsedArray = JSON.parse(property)
          let parsedArray
          try {
            parsedArray = JSON.parse(property)
          } catch (e) {
            console.log(string)
          }
          return parsedArray.map((pa) => {
            return typeof pa == 'object' ? pa.value : pa
          })
        } else {
          return ''
        }
      }
      this.sortedClaimsWithDuration.forEach((claim) => {
        if (claim.cargoType !== 'vehicle') {
          exportArray.push(claim)
        } else if (typeof claim.cargoVehicles == 'undefined') {
          exportArray.push(claim)
        } else {
          claim.cargoVehicles.forEach((cargoVehicle) => {
            let claimWithSingleCargoVehicle = { ...claim, cargoVehicle }
            exportArray.push(claimWithSingleCargoVehicle)
          })
        }
      })

      let dataToExport = exportArray.map((c) => ({
        stage: this.nullCheck(c.stage.displayName),
        client: this.nullCheck(c.client.name),
        clientClaimNumber: this.nullCheck(c.clientClaimNumber),
        isCargoClaim: c.isCargoClaim ? 'Cargo claim' : '',
        isClaimToThirdParty: c.isClaimToThirdParty
          ? 'Claim to third party'
          : '',
        isClaimToEntrustedProperty: c.isClaimToEntrustedProperty
          ? 'Claim to entrusted property'
          : '',
        isFreightForwardingClaim: c.isFreightForwardingClaim
          ? 'Freight forwarding claim'
          : '',
        isWarehouseKeepersLiability: c.isWarehouseKeepersLiability
          ? 'Warehouse Keepers Liability'
          : '',
        insuranceProvider: this.nullCheck(c.insuranceProvider),
        claimNumber: c.claimNumber ? c.claimNumber : c.id,
        insuranceClaimNumber: this.nullCheck(c.insuranceClaimNumber),
        reportedDate: this.formatDate(this.nullCheck(c.reportedDate)),
        unloadingDate: this.formatDate(this.nullCheck(c.unloadingDate)),
        incidentDate: this.formatDate(this.nullCheck(c.incidentDate)),
        dateDeclaredForInsurer: this.formatDate(
          this.nullCheck(c.dateDeclaredForInsurer)
        ),
        VehicleRegistration: c.vehicle?.registration
          ? c.vehicle.registration
          : '',
        driver: c.driver?.name ? c.driver.name : '',
        amountReserve: c.amountReserve
          ? this.formatAmount(c.amountReserve)
          : '',
        reserveReceived: c.reserveReceived ? 'Reserve received' : '',
        amountClaimed: c.amountClaimed
          ? this.formatAmount(c.amountClaimed)
          : '',
        cargoType: this.capitalizeFirstLetter(this.nullCheck(c.cargoType)),
        reportedDamage:
          c.reportedDamage && c.cargoType !== 'vehicle'
            ? formatDamagedPropertyTypes(c.reportedDamage, `claim: ${c.id}`)
            : '',
        cargoVehicleVIN: c.cargoVehicle ? c.cargoVehicle.vin : '',
        cargoVehicleDamagedParts:
          c.cargoVehicle && c.cargoVehicle.damagedParts
            ? formatDamagedPropertyTypes(
                c.cargoVehicle.damagedParts,
                `vehicle: ${c.cargoVehicle.id}`
              )
            : '',
        cargoVehicleMake: c.cargoVehicle ? c.cargoVehicle.make : '',
        cargoVehicleModel: c.cargoVehicle ? c.cargoVehicle.model : '',
        cargoVehicleCondition: c.cargoVehicle
          ? this.capitalizeFirstLetter(c.cargoVehicle.condition)
          : '',
        circumstance: c.circumstance ? c.circumstance.name : '',
        entrustedPropertyCircumstance: c.claimToEntrustedPropertyCircumstanceId
          ? this.circumstances.find(
              (circumstance) =>
                circumstance.id == c.claimToEntrustedPropertyCircumstanceId
            ).name
          : '',
        description: removeUnwantedSeparators(this.nullCheck(c.description)),
        cargoWeight: this.nullCheck(c.cargoWeight),
        finalInvoiceReceivedDate: this.formatDate(
          this.nullCheck(c.finalInvoiceReceivedDate)
        ),
        payoutDate: this.formatDate(this.nullCheck(c.payoutDate)),
        payoutReceived: c.payoutReceived
          ? this.formatAmount(c.payoutReceived)
          : '',
        amountRejected: c.amountRejected
          ? this.formatAmount(c.amountRejected)
          : '',
        amountReduced: c.amountReduced
          ? this.formatAmount(c.amountReduced)
          : '',
        outcome: removeUnwantedSeparators(this.nullCheck(c.outcome)),
        loadingLocation: removeUnwantedSeparators(
          this.nullCheck(c.loadingLocation)
        ),
        unloadLocation: removeUnwantedSeparators(
          this.nullCheck(c.unloadLocation)
        ),
        incidentLocation: removeUnwantedSeparators(
          this.nullCheck(c.incidentLocation)
        ),
        followers: filterOutFollowers(c.followers),
        factualCarrier: removeUnwantedSeparators(
          this.nullCheck(c.factualCarrier)
        ),
        isUrgent: c.isUrgent ? 'Answer for claimant within 24-72 hours' : '',
        allowDirectContact: c.allowDirectContact
          ? 'Allow direct contact with claimant'
          : '',
        claimingParty: this.nullCheck(c.claimingParty),
        cargoQuantity: this.nullCheck(c.cargoQuantity),
        requiredFileCategories: c.requiredFileCategories
          ? JSON.parse(c.requiredFileCategories).join(',')
          : '',

        // vin: c.vin ? c.vin : '',
        // reportedDate:
        //   c.reportedDate == '0001-01-01T00:00:00Z'
        //     ? ''
        //     : this.formatDate(this.nullCheck(c.reportedDate)),
        // description: removeUnwantedSeparators(this.nullCheck(c.description)),
        // location: removeUnwantedSeparators(this.nullCheck(c.incidentLocation)),
        // amountPaid: this.nullCheck(c.amountPaid),
      }))
      const link = document.createElement('a')
      link.setAttribute('href', exportClientCsv(dataToExport))
      link.setAttribute(
        'download',
        `clientClaimExport ${this.getCurrentTimeString()}.csv`
      )
      link.click()
    },

    nullCheck(value) {
      return value ? value : ''
    },
    truncate(input, length = 5) {
      if (input != null) {
        return input.length > length
          ? `${input.substring(0, length)}...`
          : input
      }
      return input
    },
    formatAmount(amount) {
      if (amount > 0) {
        let numString = parseFloat((amount / 100).toFixed(2)).toLocaleString(
          'lt-LT'
        )
        if (!numString.includes(',')) {
          numString += ',00'
        } else if (numString.indexOf(',') == numString.length - 2) {
          numString += '0'
        }
        numString = numString.replace(/\s/g, '')
        return numString
      } else {
        return amount
      }
    },
    claimDuration(item) {
      return item.processDuration
        ? getTimeDifferenceInDays(item.reportedDate, item.processDuration)
        : getTimeDifferenceInDaysUntilNow(item.reportedDate)
    },
    stageColor(displayName) {
      switch (displayName) {
        case 'Missing contact details':
          return 'red--text'
        case 'Large claim':
          return 'red--text'
        case 'Reported by insurer':
          return 'red--text'
        case 'Agreed, but unpaid':
          return 'orange--text'
        case 'Paid/Closed':
          return 'green--text'
        default:
          return ''
      }
    },
    formatDate(date) {
      if (date == '' || date == '0001-01-01T00:00:00Z') {
        return ''
      } else {
        return dayjs(date).format('YYYY-MM-DD')
      }
    },

    deleteItem(item) {
      this.editedIndex = item.id
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.$store.dispatch('mtpl/deleteClaim', this.editedIndex)
      this.closeDelete()
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    modifyStage(item) {
      this.claimIdToModify = item.id
      this.claimNumberToModify = item.claimNumber ? item.claimNumber : item.id
      this.dialogModifyStage = true
    },
    modifyStageConfirm() {
      //will make the request
      let payload = {
        id: this.claimIdToModify,
        stageId: this.stageIdToModify,
      }
      this.$store.dispatch('mtpl/updateClaim', payload).then((response) => {
        this.$store.dispatch('mtpl/updateStageToCurrent', response.data.claim)
      })
      this.closeDialogModifyStage()
    },
    closeDialogModifyStage() {
      this.dialogModifyStage = false
      this.$nextTick(() => {
        this.stageIdToModify = null
        this.claimIdToModify = null
        this.claimNumberToModify = null
      })
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    editItem(item) {
      this.editedIndex = this.mtpls.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    circumstanceTooltip(item) {
      if (
        !item.isCargoClaim &&
        !item.isClaimToThirdParty &&
        !item.isFreightForwardingClaim &&
        item.isClaimToEntrustedProperty
      ) {
        if (
          this.circumstances.findIndex(
            (c) => c.id == item.claimToEntrustedPropertyCircumstanceId
          ) != -1
        ) {
          let circumstanceName = this.circumstances.find(
            (c) => c.id == item.claimToEntrustedPropertyCircumstanceId
          ).name
          return this.$i18n.t(`circumstances.${circumstanceName}`)
        } else {
          return ''
        }
      } else {
        return item.circumstance
          ? this.$i18n.t(`circumstances.${item.circumstance.name}`)
          : ''
      }
    },

    circumstanceName(item) {
      if (
        !item.isCargoClaim &&
        !item.isClaimToThirdParty &&
        !item.isFreightForwardingClaim &&
        item.isClaimToEntrustedProperty
      ) {
        if (
          this.circumstances.findIndex(
            (c) => c.id == item.claimToEntrustedPropertyCircumstanceId
          ) != -1
        ) {
          let circumstanceName = this.circumstances.find(
            (c) => c.id == item.claimToEntrustedPropertyCircumstanceId
          ).name
          return this.truncate(
            this.$i18n.t(`circumstances.${circumstanceName}`),
            8
          )
        } else {
          return ''
        }
      } else {
        return item.circumstance
          ? this.truncate(
              this.$i18n.t(`circumstances.${item.circumstance.name}`),
              8
            )
          : ''
      }
    },
  },
}
</script>

<style lang="scss">
.custom-bg {
  background-color: #f7ebe3;
}
</style>
